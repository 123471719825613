.article-flex-container {
    display: flex;
    flex-direction: row;
    min-height: 100%;
    height: 100%;
    overflow: hidden;
}

@media (max-width: 767.98px) {
    .article-flex-container {
        flex-direction: column;
    }
}

.article-categories {
    width: 25rem;
    overflow-x: hidden;
    overflow-y: auto;
    overflow-wrap: break-word;
    box-shadow: 12px 0 12px -10px rgba(0, 0, 0, .5);
    padding-right: 1rem;
    padding-left: 1rem;
}

@media (max-width: 767.98px) {
    .article-categories {
        width: 100%;
        height: 25rem;
        box-shadow: 0 0.75rem 0.75rem -0.625rem rgba(0, 0, 0, .5);
    }
}

.article-content {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    overflow-wrap: break-word;
    padding-left: 1.5rem;
    padding-right: 1rem;
}

@media (max-width: 767.98px) {
    .article-content {
        padding-left: 1rem;
        margin-top: 1rem;
        margin-left: 0;
    }
}
