.articleSearchFlexContainer {
    width: 100%;
    display: inline-flex;
    flex-direction: row;
}

.articleSearch {
    width: 100%;
}

.articleSearchResultAfter {
    margin-bottom: 1rem;
}

.articleSearchResultTitle {
    font-size: 0.9rem;
}

.articleSearchResultContainer {
    display: flex;
    flex-direction: column;
    max-height: 7rem;
    overflow-y: auto;
}
