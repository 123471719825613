@import url('https://fonts.googleapis.com/css?family=Montserrat:700,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Merriweather&display=swap');

* {
    box-sizing: border-box;
}

html, body {
    min-height: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}

/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
    font-family: 'Merriweather', Georgia, serif;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    font-size: 2.5rem;
}

small {
    line-height: 1.75rem;
    font-size: 0.8rem;
}

small, p {
    font-family: 'Merriweather', Georgia, serif;
}

p {
    font-size: 0.9rem;
}

.height100 {
    height: 100%;
}

.scrollY {
    overflow-y: auto;
}

.centeredContextFlex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.centeredContent {
    /* set the position to absolute */
    position: absolute;
    /* set the the position of the helper container into the middle of its space */
    top: 50%;
    left: 50%;
    /* make sure padding and margin does not disturing the center calculation point */
    padding: 0;
    margin: 0;
    /* using center points of transformation */
    transform-origin: center center;
    /* calling calc() function for the calculation to move left and up the element from the center point */
    transform: translateX(calc((100% / 2) * (-1))) translateY(calc((100% / 2) * (-1)));
    text-align: center;
}

.pCenter {
    text-align: center;
}

.pLeft {
    text-align: left;
}

.pRight {
    text-align: right;
}

.pHeader {
    font-size: 1.5rem;
    font-weight: bold;
}

.maxWidthContainer {
    min-width: 100%;
    width: 100%;
}

.flexContainer {
    display: flex;
}

.flexDirCol {
    flex-direction: column !important;
}

.flexDirRow {
    flex-direction: row !important;
}

.noWrapText {
    text-overflow: ellipsis !important;
    white-space: nowrap;
}

.bottomShadow {
    box-shadow: 0 0.75rem 0.75rem -0.625rem rgba(0, 0, 0, .5);
}

.hiddenOverflows {
    overflow-x: hidden;
    overflow-y: hidden;
}

.video {
    max-width: 100%;
    width: 100%;
    height: auto;
}
