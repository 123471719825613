.articleViewerBorder {
    border: 1px solid #337ab7;
}

.articleViewerPD {
    padding: 0.25rem;
}

.articleViewerLCH_p {
    font-size: 0.8rem;
    margin: 0;
}

.articleViewerRight {
    text-align: right;
}

.articleViewerContentPB {
    padding-bottom: 0.75rem;
}

.articleViewerPopupCenter {
    /* set the the position to relative */
    position: relative;
    width: 15rem;
    height: 100%;
    margin: auto;
}

.articleViewerDivAfterComments {
    height: 3rem;
}

.articleViewerBorderRad {
    border-radius: 0.25rem;
}

.articleViewerHtmlContent {
    overflow-y: auto;
}
