.resetPassPopupCenter {
    /* set the the position to relative */
    position: relative;
    width: 15rem;
    height: 100%;
    margin: auto;
}

.resetPassAfterMargin {
    margin-bottom: 1rem;
}

.divPassResetPassPanel {
    padding: 4px;
    border: 1px solid black;
    border-radius: 8px;
    background-color: goldenrod;
    width: 15rem;
    text-align: left;
}
