.resItemTextSize {
    font-size: 0.75rem;
}

@media (max-width: 768px) {
    .resItemTextSize {
        font-size: 11px;
    }
}

.resItemP {
    padding: 0.2rem;
}

.resItemBreakWork {
    word-wrap: break-word;
}

.resItemButtonsGap {
    margin-left: 0.25rem;
}
