@import url(https://fonts.googleapis.com/css?family=Montserrat:700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Merriweather&display=swap);
* {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

html, body {
    min-height: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}

/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
    font-family: 'Merriweather', Georgia, serif;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    font-size: 2.5rem;
}

small {
    line-height: 1.75rem;
    font-size: 0.8rem;
}

small, p {
    font-family: 'Merriweather', Georgia, serif;
}

p {
    font-size: 0.9rem;
}

.height100 {
    height: 100%;
}

.scrollY {
    overflow-y: auto;
}

.centeredContextFlex {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
}

.centeredContent {
    /* set the position to absolute */
    position: absolute;
    /* set the the position of the helper container into the middle of its space */
    top: 50%;
    left: 50%;
    /* make sure padding and margin does not disturing the center calculation point */
    padding: 0;
    margin: 0;
    /* using center points of transformation */
    -webkit-transform-origin: center center;
            transform-origin: center center;
    /* calling calc() function for the calculation to move left and up the element from the center point */
    -webkit-transform: translateX(calc((100% / 2) * (-1))) translateY(calc((100% / 2) * (-1)));
            transform: translateX(calc((100% / 2) * (-1))) translateY(calc((100% / 2) * (-1)));
    text-align: center;
}

.pCenter {
    text-align: center;
}

.pLeft {
    text-align: left;
}

.pRight {
    text-align: right;
}

.pHeader {
    font-size: 1.5rem;
    font-weight: bold;
}

.maxWidthContainer {
    min-width: 100%;
    width: 100%;
}

.flexContainer {
    display: -ms-flexbox;
    display: flex;
}

.flexDirCol {
    -ms-flex-direction: column !important;
        flex-direction: column !important;
}

.flexDirRow {
    -ms-flex-direction: row !important;
        flex-direction: row !important;
}

.noWrapText {
    text-overflow: ellipsis !important;
    white-space: nowrap;
}

.bottomShadow {
    -webkit-box-shadow: 0 0.75rem 0.75rem -0.625rem rgba(0, 0, 0, .5);
            box-shadow: 0 0.75rem 0.75rem -0.625rem rgba(0, 0, 0, .5);
}

.hiddenOverflows {
    overflow-x: hidden;
    overflow-y: hidden;
}

.video {
    max-width: 100%;
    width: 100%;
    height: auto;
}

.accountMenu {
    left: -70%;
}

.accountMenu hr {
    margin-top:0;
    margin-bottom:0;
}
a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

.box-shadow {
    -webkit-box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .5);
            box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .5);
}

.nav-background {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#004ea1), to(#218cff));
    background-image: linear-gradient(#004ea1, #218cff);
}

.divNavigation {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: 3.0625rem;
    position:fixed;
    z-index:1;
}

@media (min-width: 768px) {
    .divNavigation {
        height: 3.5rem;
    }
}

/*.flex-layout {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    height: 100%;
    max-height: 100%;
}*/

.divContainer {
    height: 100%;
    overflow: hidden;
    margin-top: 4.5rem;
    padding: 0 !important;
}

@media (max-width: 767.98px) {
    .divContainer {
        margin-top: 4.33rem;
    }
}

.loginPopupCenter {
    /* set the the position to relative */
    position: relative;
    width: 15rem;
    height: 100%;
    margin: auto;
}

.loginAfterMargin {
    margin-bottom: 1rem;
}

.regPopupCenter {
    /* set the the position to relative */
    position: relative;
    width: 15rem;
    height: 100%;
    margin: auto;
}

.regAfterMargin {
    margin-bottom: 1rem;
}

.divPassReqPanel {
    padding: 4px;
    border: 1px solid black;
    border-radius: 8px;
    background-color: goldenrod;
    width: 15rem;
    text-align: left;
}

.divPassReqCenterPanel {
    position: relative;
    width: 15rem;
    height: 10rem;
    margin: auto;
}

.gridContainer {
    max-width: 768px;
    display: -ms-flexbox;
    display: flex;
}

@media (max-width: 767.98px) {
    .gridContainer {
        max-width: 100%;
    }
}

/*@media (max-width: 575.98px) {
    .gridContainer {
        max-width: 575.98px;
    }
}*/

.gridContainerShadow {
    -webkit-box-shadow: 0 0.75rem 0.75rem -0.625rem rgba(0, 0, 0, .5);
            box-shadow: 0 0.75rem 0.75rem -0.625rem rgba(0, 0, 0, .5);
}

.gridContainerOverflow {
    overflow-x: auto;
    overflow-y: hidden;
}

.gridContainerFlex {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
}

.adminAfterMargin {
    margin-bottom: 1rem;
}

.forgPopupCenter {
    /* set the the position to relative */
    position: relative;
    width: 15rem;
    height: 100%;
    margin: auto;
}

.forgAfterMargin {
    margin-bottom: 1rem;
}

.contactPopupCenter {
    /* set the the position to relative */
    position: relative;
    width: 15rem;
    height: 100%;
    margin: auto;
}

.contactAfterMargin {
    margin-bottom: 1rem;
}

.centeredButton {
    text-align:center;
}

@media (max-width: 767.98px) {
    .centeredButton {
        text-align: right;
    }
}

.popupConfirmFlexDirCol {
    -ms-flex-direction: column;
        flex-direction: column;
    width: 100%;
}

.popupConfirmFlexDirRow {
    -ms-flex-direction: row;
        flex-direction: row;
    width: 100%;
}

.popupConfirmSpace {
    padding:2px;
}

.treeListAfterMargin {
    margin-bottom: 1rem;
}

.articleBackground {
    background-color: #dbe6fb;
}

.articleComment {
    border: 1px solid #c1b9b9;
    border-radius: 0.25rem;
}

.articleCommentPad {
    padding: 0.25rem;
}

.articleCommentFlexContainer {
    width: 100%;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-direction: row;
        flex-direction: row;
}

.articleCommentUserName {
    width: 100%;
}

.articleCommentP {
    font-size: 0.7rem;
    white-space:nowrap;
    margin: 0;
}

.articleCommentMessageP {
    font-size: 0.7rem;
    margin: 0;
}

.articleCommentP_bold {
    font-weight: bold;
}

.articleCommentMessageBG {
    background-color: white; /*#f2f3f5;*/
}

.articleCommandBottomBorder {
    border-bottom: 1px solid #c1b9b9;
}

.articleCommentButtonContainer {
    width: 16px;
    height: 16px;
    border: 1px solid red;
    margin-left: 2px;
    cursor: pointer;
    border-radius: 3px;
}

.articleCommentButtonContainer p {
    margin: 0;
    padding: 0;
}

.articleViewerBorder {
    border: 1px solid #337ab7;
}

.articleViewerPD {
    padding: 0.25rem;
}

.articleViewerLCH_p {
    font-size: 0.8rem;
    margin: 0;
}

.articleViewerRight {
    text-align: right;
}

.articleViewerContentPB {
    padding-bottom: 0.75rem;
}

.articleViewerPopupCenter {
    /* set the the position to relative */
    position: relative;
    width: 15rem;
    height: 100%;
    margin: auto;
}

.articleViewerDivAfterComments {
    height: 3rem;
}

.articleViewerBorderRad {
    border-radius: 0.25rem;
}

.articleViewerHtmlContent {
    overflow-y: auto;
}

.resItemTextSize {
    font-size: 0.75rem;
}

@media (max-width: 768px) {
    .resItemTextSize {
        font-size: 11px;
    }
}

.resItemP {
    padding: 0.2rem;
}

.resItemBreakWork {
    word-wrap: break-word;
}

.resItemButtonsGap {
    margin-left: 0.25rem;
}

.editorAfterMargin {
    margin-bottom: 1rem;
}

.editorAfterMarginEnd {
    margin-bottom: 2rem;
}

.editorGridContainer {
    display: grid;
    grid-column-gap: 0;
    grid-row-gap: 0.25rem;
    grid-template-columns: auto auto 50px 50px;
    padding: 0.7rem;
}

.editorBorderBottom {
    border-bottom: 1px solid grey;
}

.editorResHeaderText {
    font-weight: bold;
}

@media (max-width: 768px) {
    .editorResHeaderText {
        font-size: 11px;
    }
}

.parag {
    margin: 0;
}

.leftMiddleContentFlex {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: left;
        justify-content: left;
}

.editorResScrollableContainer {
    max-height: 18rem;
    overflow-x: hidden;
    overflow-y: auto;
}

.editorHtmlPopupContainer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    height: 100%;
}

.editorFileUploaderContainer {
    max-height: 200px;
    overflow-y: auto;
}

.editorButtonsGap {
    display: inline;
    margin-left: 0.25rem;
}

.editorPopupGridContainer {
    display: grid;
    grid-column-gap: 0.3rem;
    grid-row-gap: 0.3rem;
    grid-template-columns: 120px auto;
}

.asResultContainer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    cursor: pointer;
}

.asResultContainer div {
    padding: 4px;
    margin: 4px;
}

.asResultContainer p {
    margin: 0;
    font-size: 0.75rem;
}

.articleSearchFlexContainer {
    width: 100%;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-direction: row;
        flex-direction: row;
}

.articleSearch {
    width: 100%;
}

.articleSearchResultAfter {
    margin-bottom: 1rem;
}

.articleSearchResultTitle {
    font-size: 0.9rem;
}

.articleSearchResultContainer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    max-height: 7rem;
    overflow-y: auto;
}

.article-flex-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    min-height: 100%;
    height: 100%;
    overflow: hidden;
}

@media (max-width: 767.98px) {
    .article-flex-container {
        -ms-flex-direction: column;
            flex-direction: column;
    }
}

.article-categories {
    width: 25rem;
    overflow-x: hidden;
    overflow-y: auto;
    overflow-wrap: break-word;
    -webkit-box-shadow: 12px 0 12px -10px rgba(0, 0, 0, .5);
            box-shadow: 12px 0 12px -10px rgba(0, 0, 0, .5);
    padding-right: 1rem;
    padding-left: 1rem;
}

@media (max-width: 767.98px) {
    .article-categories {
        width: 100%;
        height: 25rem;
        -webkit-box-shadow: 0 0.75rem 0.75rem -0.625rem rgba(0, 0, 0, .5);
                box-shadow: 0 0.75rem 0.75rem -0.625rem rgba(0, 0, 0, .5);
    }
}

.article-content {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    overflow-wrap: break-word;
    padding-left: 1.5rem;
    padding-right: 1rem;
}

@media (max-width: 767.98px) {
    .article-content {
        padding-left: 1rem;
        margin-top: 1rem;
        margin-left: 0;
    }
}

.landingPageCenter p {
    text-align: center;
}

.landingPageNavLink {
    padding: 0;
    display: inline;
}

.profPopupCenter {
    /* set the the position to relative */
    position: relative;
    width: 15rem;
    height: 100%;
    margin: auto;
}

.profAfterMargin {
    margin-bottom: 1rem;
}

.divPassProfPanel {
    padding: 4px;
    border: 1px solid black;
    border-radius: 8px;
    background-color: goldenrod;
    width: 15rem;
    text-align: left;
}

.divPassProfCenterPanel {
    position: relative;
    width: 15rem;
    height: 10rem;
    margin: auto;
}

.resetPassPopupCenter {
    /* set the the position to relative */
    position: relative;
    width: 15rem;
    height: 100%;
    margin: auto;
}

.resetPassAfterMargin {
    margin-bottom: 1rem;
}

.divPassResetPassPanel {
    padding: 4px;
    border: 1px solid black;
    border-radius: 8px;
    background-color: goldenrod;
    width: 15rem;
    text-align: left;
}

