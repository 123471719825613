.asResultContainer {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    cursor: pointer;
}

.asResultContainer div {
    padding: 4px;
    margin: 4px;
}

.asResultContainer p {
    margin: 0;
    font-size: 0.75rem;
}
