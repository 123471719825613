.editorAfterMargin {
    margin-bottom: 1rem;
}

.editorAfterMarginEnd {
    margin-bottom: 2rem;
}

.editorGridContainer {
    display: grid;
    grid-column-gap: 0;
    grid-row-gap: 0.25rem;
    grid-template-columns: auto auto 50px 50px;
    padding: 0.7rem;
}

.editorBorderBottom {
    border-bottom: 1px solid grey;
}

.editorResHeaderText {
    font-weight: bold;
}

@media (max-width: 768px) {
    .editorResHeaderText {
        font-size: 11px;
    }
}

.parag {
    margin: 0;
}

.leftMiddleContentFlex {
    display: flex;
    align-items: center;
    justify-content: left;
}

.editorResScrollableContainer {
    max-height: 18rem;
    overflow-x: hidden;
    overflow-y: auto;
}

.editorHtmlPopupContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.editorFileUploaderContainer {
    max-height: 200px;
    overflow-y: auto;
}

.editorButtonsGap {
    display: inline;
    margin-left: 0.25rem;
}

.editorPopupGridContainer {
    display: grid;
    grid-column-gap: 0.3rem;
    grid-row-gap: 0.3rem;
    grid-template-columns: 120px auto;
}
