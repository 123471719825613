.gridContainer {
    max-width: 768px;
    display: flex;
}

@media (max-width: 767.98px) {
    .gridContainer {
        max-width: 100%;
    }
}

/*@media (max-width: 575.98px) {
    .gridContainer {
        max-width: 575.98px;
    }
}*/

.gridContainerShadow {
    box-shadow: 0 0.75rem 0.75rem -0.625rem rgba(0, 0, 0, .5);
}

.gridContainerOverflow {
    overflow-x: auto;
    overflow-y: hidden;
}

.gridContainerFlex {
    display: flex;
    flex-direction: column;
}

.adminAfterMargin {
    margin-bottom: 1rem;
}
