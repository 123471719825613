a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .5);
}

.nav-background {
    background-image: linear-gradient(#004ea1, #218cff);
}

.divNavigation {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: 3.0625rem;
    position:fixed;
    z-index:1;
}

@media (min-width: 768px) {
    .divNavigation {
        height: 3.5rem;
    }
}
