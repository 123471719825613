.popupConfirmFlexDirCol {
    flex-direction: column;
    width: 100%;
}

.popupConfirmFlexDirRow {
    flex-direction: row;
    width: 100%;
}

.popupConfirmSpace {
    padding:2px;
}
