/*.flex-layout {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    height: 100%;
    max-height: 100%;
}*/

.divContainer {
    height: 100%;
    overflow: hidden;
    margin-top: 4.5rem;
    padding: 0 !important;
}

@media (max-width: 767.98px) {
    .divContainer {
        margin-top: 4.33rem;
    }
}
