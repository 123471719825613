.articleBackground {
    background-color: #dbe6fb;
}

.articleComment {
    border: 1px solid #c1b9b9;
    border-radius: 0.25rem;
}

.articleCommentPad {
    padding: 0.25rem;
}

.articleCommentFlexContainer {
    width: 100%;
    display: inline-flex;
    flex-direction: row;
}

.articleCommentUserName {
    width: 100%;
}

.articleCommentP {
    font-size: 0.7rem;
    white-space:nowrap;
    margin: 0;
}

.articleCommentMessageP {
    font-size: 0.7rem;
    margin: 0;
}

.articleCommentP_bold {
    font-weight: bold;
}

.articleCommentMessageBG {
    background-color: white; /*#f2f3f5;*/
}

.articleCommandBottomBorder {
    border-bottom: 1px solid #c1b9b9;
}

.articleCommentButtonContainer {
    width: 16px;
    height: 16px;
    border: 1px solid red;
    margin-left: 2px;
    cursor: pointer;
    border-radius: 3px;
}

.articleCommentButtonContainer p {
    margin: 0;
    padding: 0;
}
